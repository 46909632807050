<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal } from 'bootstrap-vue'
export default {
  components: {
    BCard, BButton, BModal,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      addModal: false,
      addObject: {
        adminFirstname: '',
        adminLastname: '',
        adminEmail: '',
        adminPassword: '',
        adminType: '',
      },

      editModal: false,
      editObject: {
        adminFirstname: '',
        adminLastname: '',
        adminEmail: '',
        adminType: '',
      },

      deleteObject: {},

      filterFirstname: '',
      filterLastname: ''
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.addModal = false
      this.editModal = false
      this.addObject = {
        adminFirstname: '',
        adminLastname: '',
        adminEmail: '',
        adminPassword: '',
        adminType: '',
      }

      this.filterFirstname = ''
      this.filterLastname = ''

      // Get List
      await this.$http.get('admin/list')
        .then(response => {
          this.pageArrayStock = this.pageArray = response.data
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {
        this.editObject = item
        this.editModal = true
      }

      if (type === 'delete') {
        this.deleteObject = structuredClone(item)
      }
    },
    async saveData(type) {
      if (type === 'create') {
        await this.$http.post('admin/create', this.addObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'update') {
        await this.$http.post('admin/update', this.editObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'delete') {
        await this.$http.put('admin/banned', { adminId: this.deleteObject.adminId })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'CannotDeleteYourself': errorMessage = 'Cannot Delete Yourself'; break;
              case 'PermissionError': errorMessage = 'Permission Error!'; break;
              case 'IdValid': errorMessage = 'Kendinizi Silemezsiniz!'; break;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.adminFirstname.toLocaleLowerCase().includes(this.filterFirstname.toLocaleLowerCase()) && x.adminLastname.toLocaleLowerCase().includes(this.filterLastname.toLocaleLowerCase()))
    }
  },
  watch: {
    filterFirstname: {
      handler() {
        this.filterNow()
      }
    },
    filterLastname: {
      handler() {
        this.filterNow()
      }
    }
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Administrators ({{ pageArrayStock.length }})</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Admin</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
            <th style="width: 30%"><input type="text" class="form-control" placeholder="First Name"
                v-model="filterFirstname"></th>
            <th style="width: 30%"><input type="text" class="form-control" placeholder="Last Name"
                v-model="filterLastname"></th>
            <th style="width: 20%"><input type="text" class="form-control" placeholder="Role" disabled readonly></th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.adminFirstname }}</a></td>
            <td><a>{{ item.adminLastname }}</a></td>
            <td><a>{{ item.adminType }}</a></td>
            <td>
              <!-- <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button> -->
              <button class="tb-button bg-danger tb-ml-5-px"
                @click="$bvModal.show('bv-modal-admin-delete'), tableInteractive(item, index, 'delete')">
                <span class="text-white">Delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered>
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Admin</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Admin First Name</label>
          <input type="text" class="form-control" v-model="addObject.adminFirstname">
        </div>
        <div class="col-12 mt-1">
          <label>Admin Last Name</label>
          <input type="text" class="form-control" v-model="addObject.adminLastname">
        </div>
        <div class="col-12 mt-1">
          <label>Admin E-Mail</label>
          <input type="text" class="form-control" v-model="addObject.adminEmail">
        </div>
        <div class="col-12 mt-1">
          <label>Admin Password</label>
          <input type="password" class="form-control" v-model="addObject.adminPassword">
        </div>
        <div class="col-12 mt-1">
          <label>Admin Type</label>
          <select type="text" class="form-control" v-model="addObject.adminType">
            <option value="Administrator">Administrator</option>
            <option value="Moderator">Moderator</option>
          </select>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1"
            :disabled="addObject.adminFirstname.length < 2 || addObject.adminLastname.length < 2 || addObject.adminEmail.length < 2 || addObject.adminPassword.length < 6 || !addObject.adminType"
            @click="saveData('create')">Create & Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered>
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Update Admin</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Admin Firstname</label>
          <input type="text" class="form-control" v-model="editObject.adminFirstname">
        </div>
        <div class="col-12 mt-1">
          <label>Admin Firstname</label>
          <input type="text" class="form-control" v-model="editObject.adminLastname">
        </div>
        <div class="col-12 mt-1">
          <label>Admin E-Mail</label>
          <input type="text" class="form-control" v-model="editObject.adminEmail">
        </div>
        <div class="col-12 mt-1">
          <label>Admin Type</label>
          <select type="text" class="form-control" v-model="editObject.adminType">
            <option value="Administrator">Administrator</option>
            <option value="Moderator">Moderator</option>
          </select>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1"
            :disabled="editObject.adminFirstname.length < 2 || editObject.adminLastname.length < 2 || editObject.adminEmail.length < 2 || !editObject.adminType"
            @click="saveData('update')">Update & Close</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal centered id="bv-modal-admin-delete" hide-header hide-footer>
      <h3 class="text-center mt-2">
        {{ deleteObject.adminFirstname }} {{ deleteObject.adminLastname }} 
      </h3>
      <div class="d-block text-center mt-2">
        Are you sure you want to Delete ?
      </div>
      <div class="col-12 d-flex">
        <b-button class="mt-3 btn-danger mx-1" block
          @click="$bvModal.hide('bv-modal-admin-delete'), saveData('delete')">Delete
        </b-button>
        <b-button class="mt-3 mx-1" block @click="$bvModal.hide('bv-modal-admin-delete')">Cancel
        </b-button>
      </div>
    </b-modal>

  </section>
</template>